import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from "react-router-dom";
import PackageCard from './PackageCard';
import axios from "axios";
import Loading from './Loading';
import { Helmet } from "react-helmet";
import toast, { Toaster } from 'react-hot-toast';
export default function SignIn() {
    let navigate = useNavigate(); // For Navigation Reference
    const inputRefMobile = useRef(); //For Mobile Focus Reference
    const inputRefPassword = useRef();//For Password Focus Reference

    //State Management
    const [MobileNo, setMobileNo] = useState('');
    const [Password, setPassword] = useState('');

    //Redirection Method
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };

    //Handling Input Change
    const handleChange = (event) => {
        if (event.target.name == "txtMobileNo") {
            setMobileNo(event.target.value);
        } else {
            setPassword(event.target.value);
        }
    }
    //Handling Loading 
    const [isLoading, setIsLoading] = useState(false);

    //Ajax Calling For Login
    const doLogin = () => {
        if (MobileNo == "") {
            toast.error('Enter Mobile No.')
            inputRefMobile.current.focus();
            return;
        }
        if (MobileNo.toString().length < 10 || MobileNo.toString().length > 15) {
            toast.error('Enter Valid Mobile No.');
            inputRefMobile.current.focus();
            return;
        }
        if (Password == "") {
            toast.error('Enter Password.');
            inputRefPassword.current.focus();
            return;
        }
        setIsLoading(true);
        var obj = {};
        obj = new Object();
        obj.UserId = MobileNo;
        obj.Password = Password;
        var param = new Object();
        param.procName = "SolidityLogin"
        param.para = JSON.stringify(obj);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: param
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                toast.success('Success...');
                var UserDetails = JSON.stringify(res[0]);
                UserDetails = UserDetails.hexEncode();
                localStorage.setItem("UserDetails", UserDetails);
                setTimeout(function () {
                    routeChange('home');
                }, 1000)

            } else {
                toast.error(res[0].msg);
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="css/login.css" />
            </Helmet>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            <div className="container">
                <div className="screen">
                    <div className="screen__content">
                        <form className="login">
                            <div className="logo">
                                <img src="img/logo.png?6" alt />
                            </div>
                            <div className="login__field">
                                <i className="login__icon fa fa-user" />
                                <input type="text" id="txtMobileNo" name="txtMobileNo" ref={inputRefMobile} onChange={handleChange} className="login__input" placeholder="Mobile No." />
                            </div>
                            <div className="login__field">
                                <i className="login__icon fa fa-lock" />
                                <input type="password" id="txtPassword" name="txtPassword" ref={inputRefPassword} onChange={handleChange} className="login__input" placeholder="Password" />
                            </div>
                            <button className="button login__submit" onClick={doLogin} type='button'>
                                <span className="button__text">Login Now</span>
                                <i className="button__icon fa fa-chevron-right arrow1" />
                            </button>
                        </form>
                        <div className="social-login">
                            <h5 className='registerbutton' onClick={() => routeChange('signup')}>Register Now <i className="fa fa-chevron-right" /></h5>
                            <div className="social-icons" style={{marginTop:-19}}>
                                <a href="https://t.me/minebrosofficial" className="social-login__icon fa fa-telegram" />
                                <a href="https://t.me/Minebrosadmin" className="social-login__icon fa fa-support" />
                                
                            </div>
                        </div>
                    </div>
                    <div className="screen__background">
                        <span className="screen__background__shape screen__background__shape4" />
                        <span className="screen__background__shape screen__background__shape3" />
                        <span className="screen__background__shape screen__background__shape2" />
                        <span className="screen__background__shape screen__background__shape1" />
                    </div>
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .logo {
                        width: 210px;
                        height: 140px;
                        margin: auto;
                        margin-top: -74px;
                        margin-left: -6px;
                    }
                    input{
                        font-size:15px!important;
                        color:#000;
                    }
                    .registerbutton{
                        font-size: 20px;
                        margin-top: 70px;
                        color:#000;
                    }
          `
                }}
            />
        </>
    )
}
